@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Poppins:wght@200;300;400;500;600&display=swap");

body {
  margin: 0;
  font-family: "Lato", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Lato", "Courier New", monospace;
}

.container {
  max-width: 100%;
}

main {
  min-height: 80vh;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
  text-transform: capitalize;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

.rating span {
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}

.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-container {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  margin-left: 0;
  margin-right: 0;
}

.carousel a {
  margin: 0 auto;
}

#basic-nav-dropdown,
#username {
  color: black;
}

.fa-shopping-cart {
  color: black;
}

.cart-text {
  color: black !important;
}

.signin-text {
  color: black !important;
}

.card {
  height: 85%;
  border: none;
}

.img-wrapper {
  overflow: hidden;
}

.hover-zoom:hover {
  transform: scale(1.06);
}

.category-link a:hover {
  color: #f4791a;
}

.button-container {
  display: flex;
  justify-content: center;
}

.button-container button {
  margin: 5px;
  flex: 1;
}

@media (max-width: 600px) {
  .button-container {
    flex-direction: column;
    align-items: stretch;
  }

  .button-container button {
    margin: 5px 0;
  }
}

@media screen and (max-width: 767px) {
  .main-navbar {
    padding: 0.5rem 1rem;
  }
}

.image-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  max-width: 800px;
  margin: 0 auto;
}

.custom-image {
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid #b87333;
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .image-container {
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 5px;
    justify-content: flex-start;
  }

  .custom-image {
    flex-shrink: 0;
    width: 100px;
  }
}

.otpContainer {
  margin-top: 5%;
}

.otp-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.otp-buttons .otp-btn {
  margin-right: 10px;
}

.otp-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0.5rem 1rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.App {
  text-align: center;
}

.card-img-top:hover {
  transform: scale(1.05);
}

.category-card {
  width: 20rem;
  /* border: 1px solid #25d366 ; */
}

.best-selling-card-grp {
  display: flex;
  justify-content: center;
}

.card:hover .homescreen-title {
  color: #f4791a;
}

.homescreen-title {
  font-size: 1rem;
  text-align: center;
}

.card-title,
.card-body {
  text-align: center;
  font-size: 1rem;
  padding: 1.25rem 0;
}

@media screen and (max-width: 767px) {
  .homescreen-title {
    font-family: "Poppins";
    font-size: 0.5rem;
    text-align: center;
    padding: 0 0.2rem;
  }

  .title-rudraksha {
    margin-left: -10px;
  }

  .faqs-container {
    font-weight: 400;
    font-size: medium;
  }
}

.row {
  margin-left: auto;
  margin-right: auto;
}

.top-product-card {
  width: 200px;
  margin: auto;
}

.list-unstyled li > a {
  text-decoration: none;
}

.list-unstyled li > a:hover {
  color: #f4791a;
}

.footer-name:hover {
  color: #f4791a;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.faqs-container {
  font-weight: 400;
  font-size: large;
}

.faqs-body-text {
  font-weight: 200;
  font-size: 0.9rem;
}

.card-header {
  background-color: #ffffff;
}

/* .accordion > .card > .card-header,
.accordion > .card > .card-body {
  display: flex;
  justify-content: center;
} */

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -90%);
  -ms-transform: translate(-50%, -90%);
  transform: translate(-50%, -90%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 200px;
  margin: 0px auto 20px;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-size: 236px;
  font-weight: 200;
  margin: 0px;
  color: #211b19;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound .notfound-404 h2 {
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
  color: #211b19;
  background: #fff;
  padding: 10px 5px;
  margin: auto;
  display: inline-block;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
}

.notfound a {
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  padding: 13px 23px;
  background: #ff6300;
  font-size: 18px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  color: #ff6300;
  background: #211b19;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 h1 {
    font-size: 148px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 148px;
    margin: 0px auto 10px;
  }

  .notfound .notfound-404 h1 {
    font-size: 86px;
  }

  .notfound .notfound-404 h2 {
    font-size: 16px;
  }

  .notfound a {
    padding: 7px 15px;
    font-size: 14px;
  }
}
.logo-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  animation: fade-in 2s ease-in forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.logo {
  position: relative;
  animation: logo-appear 2s ease-in-out forwards;
}

@keyframes logo-appear {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  80% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.shine {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, transparent 100%);
  opacity: 0;
  transform: translateX(100%);
  animation: shine 2s 0.8s ease-in-out forwards;
}

@keyframes shine {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(0%);
  }
}

.logo-loader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #FFe8d6 10%, #ffffff 50%, #FFe8d6 90%);
  background-size: 100% 200%;
  animation: animated-background 8s linear infinite;
  z-index: -1;
}

@keyframes animated-background {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.logo-loader img {
  width: 200px;
  height: 200px;
}
